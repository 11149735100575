.enter--left {
    animation: moveInLeft .7s ease-out;
}
.enter--right {
    animation: moveInRight .7s ease-out;
}

.fadeout--bottom {
    transform: translateX(-500rem);
    animation: moveOutBottom .5s ease-in;
}
.show--bottom {
    transform: translateX(0);
    animation: moveInBottom 1s ease-out;    
}
.button-pop:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
.opacity--0 {
    opacity: 0;
}
.opacity--1 {
    opacity: 1;
}
.load--slow-opacity {
    opacity: 1;
    transition: opacity 2s ease-out;
}
.close--slow-opacity {
    opacity: 0;
    transition: opacity .8s ease-out;
}
.load--slow-delay-1-5 {
    transition-delay: 1.5s;
    animation-delay: 1.5s;
}
.load--slow-delay-2 {
    transition-delay: 2s;
    animation-delay: 2s;
}
.load--slow-delay-1 {
    transition-delay: 1s;
    animation-delay: 1s;
}
.load--slow-delay-0-5 {
    transition-delay: .5s;
    animation-delay: .5s;
}
@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }
    80% {
        transform: translateX(2rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0rem);
    }
}
@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }
    80% {
        transform: translateX(-2rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0rem);
    }
}
@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0rem);
    }
}
@keyframes moveOutBottom {
    0% {
        opacity: 1;
        transform: translateY(0rem);
    }
    100% {
        opacity: 0;
        transform: translateY(3rem);
    }
}
@keyframes growFromCenterline {
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
    0% {
        opacity: 0;
        transform: scaleY(0);
    }
}
@keyframes shrinkFromCenterline {
    0% {
        opacity: 1;
        transform: scaleY(1);
    }
    100% {
        opacity: 0;
        transform: scaleY(0);
    }
}


.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 50rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}
.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}
