.ArrowButton__wrapper {
    border: #fff3f3 solid .4rem;
    max-width: 25rem;
    margin: 2rem auto;
    z-index: 100;
    position: relative;
}
.ArrowButton__title{
    color: #412222;
    font-family: "Open Sans";
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -1.6px;
    text-transform: uppercase;
    /* border-right: #fff3f3 solid .4rem; */
    padding: 0 1rem;
    line-height: 4rem;    
}
.ArrowButton-arrow {
    margin: 0 auto;
    width: 20%;
}
.ArrowButton__wrapper:hover{
    background: #fff3f3;
    border-color: #412222;
    cursor: pointer;
}
