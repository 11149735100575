.menu-wrapper_open {
    align-content: center;
    padding: 3rem;
    position: fixed;
    top: 0;
    right: 1vw;
    margin: 1vh 2.5vw;
    height: 98vh;
    width: 90vw;
    z-index: 100;
}
.menu__link {
    letter-spacing: .3rem;
    font-size: 3rem;
    line-height: 4rem;
    display: block;
}
.menu__link:hover  {
    background: blue;
    color: #FFF3F3;
    border-radius: .5rem;
    cursor: pointer;
}
.Menu__link--underline {
    border-bottom: 5px solid;
}
.Menu__img {
    margin-bottom: 2rem;
    width: 100%;
    margin: 2rem;
}
.Menu__vignette:hover {
    background-color: #2FCE6E;
    border-radius: 5px;
}
.portrait__wrapper {
    /* height: 10rem; */
}
.portrait {
    height: 10rem;
    width: 8rem;
    border-radius: 50%;
    background-image: url("../assets/images/characters/Chris-600.jpg");
    background-size: 150%;
    background-position: center;
}

@media screen and (min-width : 450px) {
    .menu__link {
        font-size: 3rem;
    }
}
@media screen and (min-width : 650px) {
    .Menu__img {
        width: 30rem;
    }
}
@media screen and (min-width : 950px) {
    .menu-wrapper_open {
        height: auto;
        width: 90vw;
        right: 1.5vw;
    }
}
@media screen and (min-width : 1900px) {
    .menu-wrapper_open {
        height: auto;
        width: 57vw;
        right: 19vw;
        top: 6vh;
    }
}
