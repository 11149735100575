/* Text Utilities */
.open-sans {  font-family: 'Open Sans', sans-serif;}
.oswald {    font-family: 'Oswald', sans-serif;}
.center {    text-align: center;}
.left {    text-align: left;}
.bold { font-weight: 700;}
.mid-page-callout { 
    font-size: 3rem;
    line-height: 2.8rem;
    margin-bottom: 2rem;
}
.mid-page-title {
    font-size: 2.8rem;
    font-weight: 700;
}
.mid-page-title--2 {
    font-size: 3rem;
    letter-spacing: .3rem;
}
.white { color: #fff3f3;}
.black { color: #412222;}
.headline {
    letter-spacing: .72rem;
    text-transform: uppercase;
}
.mid-page-text {
font-size: 2.8rem;
font-weight: 400;
}
.underline {
    text-decoration: underline;
}
/* LAYOUT */
.main__wrapper {
    height: 100%;
    width: 100%;
}
.page-wrapper { 
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    overflow: hidden;
    padding: 2rem;
}
.padding-2 {
    padding:2rem
}
.responsive-flex-row {
    display: flex;
    flex-flow: column nowrap;
}
.flex-column {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
}
.flex-row {
    display: flex;
    flex-flow: row wrap;
}
.flex-center {
    justify-content: center;
}
.flex-align-start { align-content: flex-start;}
.flex-align-center { align-items: center;}
.grid-1x1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: auto;
}
.grid-position-1 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}
.grid-center {
    align-self: center;
    justify-self: center;
}
.grid-end {
    align-self: flex-end;
    justify-self: flex-end;
}
.grid-start {
    align-self: flex-start;
    justify-self: flex-start;
}
.grid-bottom {
    align-self: flex-end;
    justify-self: center;
}
.z-index-top {
    z-index: 99;
}
.z-index-mid {
    z-index: 50;
}
.z-index-back {
    z-index: -1;
}
.inline-block {
    display: inline-block;
}
.split-page-img {
    width: 100%;
    object-fit: contain;   
}
.space-between {
    justify-content: space-between;
}
.space-around {
    justify-content: space-around;
}
.align-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.full-screen {
    height: 100vh;
    width: 100%;
}
.max-size {
    height: 100%;
    width: 100%;
}

.auto-size {
    height: auto;
    width: auto;
}
.page-center {
    margin: 0 auto;
}
.margin-1 {
    margin: 1rem;
}
.margin-3 {
    margin: 3rem;
}
.margin-5 {
    margin: 5rem;
}
.margin-top-2 {
    margin-top: 2rem;
}
/* BACKGROUNDS */
.page-wrapper { 
    background-size: cover;
}
.mid-page {
    height: auto;
    width: 100%;
    padding: 4rem 2rem;
}
.mid-page_underline {
    margin-bottom: 2rem;
    height: 3rem;
}
.page-runout {
    width: 100%;
    height: 75vh;
}
.overflow-x {overflow-x: hidden}
.background--yellow--dark {
    background: #fccc05;
}
.background--yellow--midtone {
    background: #FDDB18;
}
.background--yellow {
    background: #FEE934;
}
.background--white {
    background-color: #FFF3F3;
}
.background--black {
    background-color: #412222;
}
.border--white {
    border-color: #FFF3F3;
}
.border--black {
    border-color: #412222;
}
/* MARGINS */

/* IMAGES */
.vignette-wrapper {
    width: 100%;
    overflow: visible;
    z-index: 50;
    position: relative;
    padding-bottom: 2rem;
}
.vignette {
    object-fit: contain;
    width: 100%;
}
.down-arrow {
    position: relative;
    margin-bottom: 4rem;
}

.vignette--small {
    width: 60%;
}
.hide-on-small, .hide-on-mobile {
    position: absolute;
    right: 100vw;
}

.pointer:hover {
    cursor: pointer;
}
.hover--blue-bar:hover {
    border-radius: 5px;
    background-color: blue;
    color: white;
}
.blue-on-hover:hover  {
    border-radius: .5rem;
    background: rgb(255,255,255);
    background: -moz-radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(24,55,253,1) 100%);
    background: -webkit-radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(24,55,253,1) 100%);
    background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(24,55,253,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#1837fd",GradientType=1);
}


@media screen and (min-width : 480px) {
    .responsive-flex-row {
        flex-flow: row wrap;
    }
    .vignette-wrapper {
        width: 40%;
        display: block;
        margin: 0 auto 2rem auto; 
        position: relative;
        top: 4rem;
    }
    .vignette--small {
        height: 15rem;
        width: 15rem;
    }
    .mid-page {
        height: auto;
        width: 100%;
        padding: 4rem 2rem;
    }
    .mid-page-callout { 
        font-size: 5rem;
        line-height: 4.5rem;
    }
    .vignette--small {
        height: 18rem;
        width: 18rem;
    }
    .hide-on-mobile {
        position: relative;
        right: 0;
    }
    .only-mobile {
        display: none;
    }
    
}
@media screen and (min-width : 950px) {
    .hide-on-small {
        position: relative;
        right: 0;
    }
    .hide-on-large {
        display: none;
    }
    .vignette-wrapper {
        width: 50%;
    }
    .vignette--small {
        height: 25rem;
        width: 25rem;
    }
}
@media screen and (max-width : 950px) {
    .page-center--small {
        margin: 0 auto
    }

}
@media screen and (min-width : 1200px) {
    .page-center {
        top: 60rem;
    }
    .vignette-wrapper {
        width: 30%;
    }
    .vignette--small {
        height: 30rem;
        width: 30rem;
    }
}
@media screen and (min-width : 1900px) {

    .main__wrapper {
        width: 60vw;
        margin: 2vh auto;
        box-shadow: 10px 5px 30px rgba(0, 0, 0, .3);
    }
    .full-screen {
        height: 96vh;
    }
   
    
}
