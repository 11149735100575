.home-wrapper {
    background-image: url('../assets/images/backgrounds/horizon-intro-yellow.svg');
}
.mid-page_home {
    background: #fccc05;
    width: 100%;
    height: 80vh;
}

.page-runout--yellow {
    background: rgb(253,219,24);
background: -moz-linear-gradient(180deg, rgba(253,219,24,1) 25%, rgba(255,255,255,1) 63%);
background: -webkit-linear-gradient(180deg, rgba(253,219,24,1) 25%, rgba(255,255,255,1) 63%);
background: linear-gradient(180deg, rgba(253,219,24,1) 25%, rgba(255,255,255,1) 63%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fddb18",endColorstr="#ffffff",GradientType=1);
}
.mid-page_home__title {
    margin: 0 auto;
    text-shadow: 0 2px 3px rgba(0, 0, 0, 0.16);
    color: #fff3f3;
    font-family: "Open Sans";
    font-weight: 700;
    letter-spacing: -.7rem;
    line-height: 8rem;
    font-size: 8rem;
    position: relative;
}


@media screen and (min-width : 540px) {
    .mid-page_home__title {
        font-size: 10rem;
        line-height: 9rem;
        width: 60%;
    }
}
@media screen and (min-width : 1200px) {
    .mid-page_home__title {
        font-size: 17rem;
        line-height: 15rem;
    }
}
