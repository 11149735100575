.social-media_link {
    height: 4rem;
    width: 4rem;
    display: block;
    padding: 1rem;
}
.social-media_link--large {
    height: 6rem;
    width: 6rem;
    border: #212121 solid 2px;
    border-radius: 5px;
}
