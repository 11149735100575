* {
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  color: #412222;
  text-decoration: none;
  text-align: center;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  /* outline: 1px solid greenyellow; */
}
#root {
  height: 100vh;
}


h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
}

.App {
  text-align: center;
}
.test-box {
  width: 50rem;
  height: 50rem;
  background-color: aqua;
}
.blue {
  background-color: blue;
}
.red {
  background-color: red;
}
.ras-sectioned {
  overflow: scroll;
  display: block;
}
.ras-page {
  display: block;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    background-color: transparent;
}
.slide-page {
  overflow-x: hidden;
  overflow-y: scroll;
  /* display: flex;
  align-items: flex-start;
  flex-flow: column;
  justify-content: flex-start; */
    height: 100%;
    width: 100%;
}
.slide {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;

}

.ras-section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100vw;
    position: relative;
}
.ras-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.ras-content__main {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
