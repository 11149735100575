.Page-splitter__animated {
    width: 100%;
    height: 100%;
    clip-path: polygon(0 45%, 100% 45%, 100% 100%, 0% 100%  );

    transition: clip-path 1s ease-out .5s; 
}

.Page-splitter__animate {
    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100% );
}
