.InfoCard-wrapper {
    width: min-content;
    height: min-content;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

}
.InfoCard-wrapper p {
    margin: 0;
    width: 100%;
}
.InfoCard-title {
    width: 100%;
    vertical-align: bottom;
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: .2rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.InfoCard__list-item {
    min-width: 35rem;
    color: #fff3f3;
    font-family: "Open Sans";
    font-size: 2rem;
    text-align: left;
    padding-left: 2rem;
}

@media screen and (min-width : 450px) {
    .InfoCard-title {
        width: 18rem;
        font-size: 2.5rem;
        line-height: 2.8rem;
    }.InfoCard__list-item {
        min-width: 25rem;
        font-size: 2rem;
        /* line-height: 9rem; */
    }
}
@media screen and (min-width : 1200px) {
    .InfoCard-title {
        min-width: 25rem;
        font-size: 3rem;
        height: 8rem;
        line-height: 2.`0;
    }
        .mid-page_skills__title {
        font-size: 5rem;
    }
}
