.nav-wrapper {
    margin: 0 1rem;
    padding-top: 2rem;
    z-index: 100;
    align-items: flex-start;
}
.sticky  {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
}
.nav__link {
    font-size: 3.6rem;
    font-weight: 700;
    color: #FFF3F3;
}
.NavBar__hover--white:hover {
    border-bottom: #FFF3F3 solid 5px;
}
.NavBar__hover--black:hover {
    border-bottom: #412222 solid 5px;
}
.nav__logo {
    padding: 1rem;
    height: 7rem;
}
.nav__logo-name {
    width: 100%;
    text-align: center;
}


@media screen and (min-width : 480px) {
    .nav__logo-name {width: 25rem;}
    .nav__logo-name { margin-top: 2rem; }
    .nav-bar-margins { 
        margin-top: 1.6rem;
    }
    .nav__link {
        font-size: 2.8rem;
        margin-right: 2rem;
    }
    .nav-wrapper {
        margin: 0 4rem;
    }
}

@media screen and (min-width : 1224px) {
    html {
        font-size: 10px;
    }
}
@media screen and (min-width : 1600px) {
    html {
        font-size: 12px;
    }
}
