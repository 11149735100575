.ProjectDetail__back-button {
    color: #412222;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    text-transform: uppercase;
    margin-right: 2rem;
    display: inline;
    vertical-align: middle;
}
.ProjectDetail__back-button:hover {
    font-weight: 700;
}
.ProjectDetail__iframe {
    width: 90vw;
    height: 90vh;
}

@media screen and (min-width : 480px) {
    .ProjectDetail__back-button {
        font-size: 2rem;
    }
}
