.Preview__animate {
    z-index: 100;
    height: 35rem;
    width: 35rem;
    padding: 3rem;
    margin-right: 3rem;
}
.Preview__wrapper {
    background: rgba(0,0,0,.75);
    color: #FFF3F3;
}
.Preview__title, .Preview__wrapper > a {
    color: #FFF3F3;
}
.Preview__title {
    font-size: 3rem;
}

.Preview__nav-text{
    line-height: 7rem;
    vertical-align: middle;
    animation: fadeInLeft 1s ease-out;
}
.Preview__nav-text:hover {
    font-weight: 700;
}
.Preview__img {
    height: 1rem;
}

@media screen and (min-width : 950px) {
    .Preview__wrapper {
        height: 30rem;
        width: 30rem;
    }
}
@media screen and (min-width : 1200px) {
    .page-center {
        top: 60rem;
    }
    .vignette--small {
        height: 30rem;
        width: 30rem;
    }
}



.Preview__wrapper {
    transform: scaleY(0);
    transition: all .5s ease-out;
}
.Preview__open {
    opacity: 1;
}
.Preview__open .Preview__wrapper {
    opacity: 1;
    transform: scaleY(1);
    animation: growFromCenterline .5s ease-out;
}
.Preview__close .Preview__wrapper {
    transform: scaleY(0);
    animation: shrinkFromCenterline .5s ease-out;
}
